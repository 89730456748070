
  
body {
    background-color: white !important;
    /* background-image: url('../images/stock-image/sky3-77kb.jpg'); */
    background-size: cover;
    /* background: linear-gradient(to bottom, #57c1eb 0%,#246fa8 100%);  */
    background: linear-gradient(to bottom, #57c1eb 0%,#ddd 100%);

    /* New Style - White BG */
    background: linear-gradient(to bottom, white 0%,#ebebeb 100%);
     /* New Style - White BG */
    
    /* background-repeat: no-repeat; */
    background-attachment: fixed;
    /* background-color: skyblue;
    background-color: #868686; */
    /* background-color: #3F85B7; */
    /* background-color: cornflowerblue; */
    /* height: 150vh; */

    
  }

  .form-control {
    margin-bottom: 5px;
  }
p.firstp {width: 100%; float: left; margin-left: 10%; margin-top: -23px; font-size: 14px;}
    p.secondp {  float: left;margin-left: 36%; margin-top: -30px; font-size: 14px;}
    p.thiredp { float: left;margin-left: 58.5%; margin-top: -30px; font-size: 14px;}
    p.fourthp { float: left;margin-left: 85%; margin-top: -30px; font-size: 14px;}
/*p.firstp {
	float: left;;margin-left: 56px; margin-top: -23px; font-size: 18px;	
     }
    p.secondp {
        
    float: left;;margin-left: 150px; margin-top: -23px; font-size: 18px;	
        
        
    }

    
    p.thiredp {
        
    float: left;;margin-left: 185px; margin-top: -23px; font-size: 18px;	
        
    }
    p.fourthp {
    
    float: left;;margin-left: 104px; margin-top: -23px; font-size: 18px;
    
    }
*/
.nav-tabs > li.active:after {
    border-bottom-color: transparent !important;
}
.suggestions-container {
	z-index: 9;
}
.alternativeformclass{
/* margin-top: -20px;     */
}    
     
.btn-default {
    border-color: #ccc;
}

.tab-content .choice {
  text-align: center;
  cursor: pointer;
  margin-top: 38px;
}

.tab-content .choice i {
    font-size: 32px;
    line-height: 55px;
}

.btn-radio {
	width: 100%;
}
.img-radio {
	opacity: 0.8;
	margin-bottom: 5px;
}

.space-20 {
    margin-top: 20px;
}

/* active buttons */
#status-buttons a.active span.round-tabs.one { 
    background: rgb(51, 122, 183); 
    color: #fff
}

#status-buttons a.active span.round-tabs.two { 
    background: rgb(51, 122, 183); 
    color: #fff
}

#status-buttons a.active span.round-tabs.three { 
    background:rgb(51, 122, 183); 
    color: #fff
}

#status-buttons a.active span.round-tabs.four { 
    background: rgb(51, 122, 183); 
    color: #fff
}


.iradio_buttons {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    background: #febe29 no-repeat;
    border: none;
    cursor: pointer;
}
.iradio_buttons {
    background-position: -120px 0;
}
.iradio_buttons.hover {
    background-position: -144px 0;
}
.iradio_buttons.checked {
    background-position: -168px 0;
}

.text-style{
	font-size:27px;
	font-weight:bold;
}

.mb20{
	margin-bottom:20px;
}

.btn-style {
    background: #2a68af;
    padding: 10px 26px;
    border: none;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.15);
    border-radius: 4px;
    /* text-transform: uppercase; */
    color: #fff;
    font-size: 12px;
}

.bold{font-weight:bold;}

.fldst {
    border: 1px solid #ccc;
    padding: 30px 15px 0;
    margin-top: 50px;
	float: left;
    width: 100%;
}

.pos-rel {
    position: relative;
}

.fhd {
    position: absolute;
    top: -17px;
    background: #fff;
    border: 1px solid #ccc;
    padding: 5px 16px;
	color: blue;
}

.box {
    border: 1px solid #222;
    padding: 15px;
    border-radius: 4px;
    background: #fff;
}

.box .border-bottom {
    float: left;
    width: 100%;
    border-bottom: 1px solid #222;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.mt30{
	margin-top:30px;
}

.mt10{margin-top:10px;}

.backg{
	background:#ebebe7;
	padding:15px;
}

.arrowimg{position:relative;top:40px;}

.mt50{margin-top:50px;}

.mt30{margin-top:30px;}
.board {
    float: left;
}
.nav-tabs {
    border-bottom: none;
}

.color-black{color:#000;}

.color-blue{color:blue;}

.fw600{
    font-weight:600;
    padding: 1%;

}

.border-bottom{
	border-bottom: 1px solid #222;
	padding-bottom:10px;
	margin-bottom:10px;
}
.gen{
	    float: left;
    width: 100%;
}
.form-required{color:red;}

.radiowrap {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    /*margin-bottom: 12px;*/
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 300;
    line-height: 20px;
}
.radiowrap_for_alt {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 300;
    line-height: 20px;
}

/* Hide the browser's default radio button */
.radiowrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ccc;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radiowrap:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radiowrap input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiowrap input:checked ~ .checkmark:after {
   /* display: block;*/
}

/* Style the indicator (dot/circle) */
.radiowrap .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
.mr15{margin-right:15px;}












.review-img {
    display: block;
    margin: auto;
    width: 120px;
}

@media(max-width:767px){
	.arrowimg {
    position: relative;
    top: 0px;
    margin: 20px;
}
.board {
    width: 100%;
    height: auto !important;
	padding: 15px;
}
span.round-tabs {
    font-size: 14px;
    width: 50px;
    height: 26px;
    line-height: 22px;
}
.liner {
    display: none;
}

.review-img {
    width: 80px;
}

}
.fldstnew {
    border: 1px solid #ccc;
    padding: 30px 15px 0;
    margin-top: 50px;
	float: left;
}
.bg-gray{
    background-color: #c7cacc;
     padding: 2%;
     border-radius: 5px;
     float: left;

     background-color: #f9f6f6;
    box-shadow: 0px 0px 2px #615757;
    margin-bottom: 10px;
    padding: 0px !important;
    /* background-image: url('../images/stock-image/sky2.jpg'); */
    background-size: cover;
    background: #F3EDE1;
    /* background: linear-gradient(90deg, rgba(252,235,183,1) 0%, rgba(9,62,121,0.7092086834733893) 100%, rgba(0,212,255,1) 100%); */
}
.bg-blue{
    word-wrap: break-word;
    background-color: #335e8f41;
    border-radius: 5px;
    padding: 15%;
    height: auto;
    font-size: xx-large;
    text-align: center;


}
.bg-light-blue{
	background-color: #4cdff6bf;
	text-align: center;
	 padding: 2%;
     border-radius: 30px;

    /* background-color: #f1f8f9; */
    border-radius: 5px;
    /* box-shadow: 0px 0px 2px gray; */
    margin-top: 15px;

    border-top: 10px solid #79787b;
    box-shadow: 0px 0px 0px 1px #a29f9f;
}

.bg-light-green{    /*alok*/
    margin-top: 0;
    /* margin-bottom: 4%; */
    background-color: #25d405c4;
    text-align: center;
    padding: 3%;
    border-radius: 30px;
    font-weight: 700;
}

.btn1-style{
display: table-cell;
vertical-align: middle;
text-align: center;    
width: 100%;
/* padding: 5%; */
border: 1px solid rgba(161,162,163,1);
-webkit-border-radius: 15px;
border-radius: 0px;
height: 65px;
/* height: 40px; */
margin-bottom: 15px;
-webkit-transition:  0.3s; /* Safari */
  transition:  0.3s;
  
  /* background: #f9f6f6; */
  border: 1.5px solid #e5d3d3;
  background-color: #E4E5E5;
  font-weight: 600;
  /* color: #7c7178; */
  color: #555254;
  border-radius: 5px;

}
a{
    cursor: pointer;
}
.btn1-style:hover{
    background: #c7cacc;
    border: 1px solid rgb(206, 218, 230);
    /* box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.3) ;  */
   cursor: pointer;
   transform: scale(1.05);

   background: #f1f5ee;
    border: 1px solid #e1dada;
    background-color: #005EC2;
    color: white;

    
    }
 
.big_text{
    font-size: 22px;

}
.txt_aln_rt{
    text-align: right;
}

.dt{
    display: table;
    width: 100%;
}
.dtc{
    display: table-cell;
    width: 100%;
}
.col-sm-6{
    padding-right: 15px;
    padding-left: 0px;
    padding-bottom: 15px;
}
.col-sm-3{
    padding-right: 5px;
    padding-left: 5px;}

.col-sm-4{
    padding-right: 0px;
    padding-left: 15px;
    padding-bottom: 15px;
}
.col-sm-5{
    padding-right: 0px;

}
.pd15{
    padding-bottom: 15px;
}
h2, h3, h4, h5{
    text-align: center;
}
.ratingimg{
    width: 100%;
}
.bld_fnt{
    font-size: small;
    font-weight: 600;
}
.bold_fnt{
    font-size: x-large;
    font-weight: 600;
    top: 9px;
    position: relative;
}
.tac{
    text-align: center;

}
.wd-fll{
    width: 100%;
    
}
.mar-btm-15{
    margin-bottom: 15px;
}
.pd0{
    padding: 0px;
}
.pd2percent{
    padding-top: 2%;
}
.pd11percent{
    /* padding-top: 11%; */
}
.pad_l_0px{
    padding-left: 0%;
}
.pad_r_0px{
    padding-right: 0%;
}
/* change for patya */
.panel-default>.panel-heading {
    color: #333;
    text-align: center;
    background-color: #f5f5f500;
    border-color: #ddd0;
}

.panel-default {
    border-color: #ddd;
}
.panel{
    border: 0px;
    box-shadow: none;
}
.mar0{
    margin: 0px;
}
.asteriks{
    color: red;
}
.txt_aln_jt{
    text-align: center;
}
.mat_top_15{
    margin-top: 40px;
}
.For_more_txt_in_bttn{
    font-size: 12.5px;
    padding: 5px;
    margin: 0px;
}
.pos_absu{
    position: absolute;
}
.l0b0{
    left: 0px;
    bottom: 0px;
}
.margin-bttm-20{
    margin-bottom: 5%;
}
.reject_bnnr{
    background-color:#f3142b;
    border:5px #c53300;
    color: white;
    margin-bottom: 5px;

    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px #ff00002e;
    margin-bottom: 15px;
    border: 5px solid white;
    padding: 10px;
    margin-top: 20px;
}
.reject_fclr{
        color: white;

}
.reject_end{
                padding: 0px;

}
    .brdr{
    border: 1px #125d18c9 solid;

    /* New Style */
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px #d0cbcb;
    margin-top: 10px;
    border: none;
}






/*ARIJIT DAS 14.06.2019*/

@media (max-width: 479px) {
   .backing{
     margin-top: 32px;  
   }
    .fullw{
    width:100%;       
   }
    .alyuv{
    width:100%;      
  }
    .buttonextraprop{
    width:100%;   
   }
    .enlawimg {
        width:50%;
        height: 0px;
        margin-top: 25px;
        margin-left:10px;
            }
                
        .ar_flda {
        width:100%;
        }
        .ar_flimg {
        width:75%;	
        }
        .ar_mdflda {
        margin-top:25px;	
        }	
        .ar_flimg2 {
        width:75%;	
        /* margin-left:25px;	 */
        }	
        p.firstp {
         display: none;   
        }
        p.secondp {
        display: none;    
        }
        p.thiredp {
        display: none;       
        }
        p.fourthp {
        display: none;
        }
        .refund{
        height:20px;
        width:100%;    
        background: #2a68af;
        }
        .ar_rdio {
        width:100%;
        }  
        .enwriting{
        width: 50%;
        margin-left:90px;   
        margin-bottom: 10px; 
        }  
        p.firsttext{
        font-size: 1.875em;
        }
        
        .firstblock{
        height:200px;    
        /* width:100%;     */
        
        }
        .secondblock{
        height:20px;    
        width:100%;    
        display:none;
        }
        .thiredblock{
        height:200px;    
        width:100%;    
        margin-top:25px;
        }
        .lockimg{
        width:50%;
        height: 0px;
        margin-top: 25px;
        margin-left:10px;
        }
        .lockwrt{
        width: 50%;
        margin-left:90px;   
        margin-bottom: 10px; 
        
        
        } 
        p.secondtext{
        font-size: 1.875em;
        }   
        /*.panel-default>.panel-heading {
       display:none;    
        }
		*/
        .form-groupa{
        display: block;    
        }
        .aklau{
        width:100%; 
        height:400px;   
        }
        .mlrespon{
        z-index:99999;     
        }

        .review-img {
            width: 50px;
        }
       
}

/*ARIJIT DAS 14.06.2019*/



.design_auto {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
} 
.bordernone{
border: none !important;
}
.design_auto .mat-form-field-flex {
border: none;
height: 40px;
}
.design_auto .bordernone{
border: none;
height: 40px;
width: 100%;
}


.ovscr{
    overflow-x: scroll;
}
.file_display{
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 160px;
    padding: 20px;
    overflow: hidden;
    border: none;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    font: normal normal bold 16px/1 "Times New Roman", Times, serif;
    color: rgba(0,0,0,1);
    text-align: center;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    background: rgba(64, 191, 168, 0.33);
    -webkit-box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.3) ;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.3) ;
    width: 90%;
    margin-bottom: 4%;

}

.fl_name{
    padding: 5px;
    margin-top: 24px;
    border: none;
-webkit-border-radius: 15px;
border-radius: 15px;
-webkit-box-shadow: 3px 4px 10px 2px rgba(0,0,0,0.3) ;
box-shadow: 3px 4px 10px 2px rgba(0,0,0,0.3) ;
background: #edff0045;

}
/* autocomplet design */
#cdk-overlay-0{
	width: auto !important;
}
#cdk-overlay-1{
	width: auto !important;
}
/* autocomplet design */

.design_auto .mat-form-field-flex {
	border: none !important;
	height: 40px;
}
.lh0{
	line-height: 1;
}
.small_fsize{
	line-height: 0;
	font-size: small;
}
.fl_wd{
	width: 100%;
}



.flight-stock-img img{
    width: 120px;
    position: relative;
}

.flight-stock-img img.rotate{
    transform: rotate(45deg);
    left: -20px;
    /* position: relative; */
}

.progress {
   height: 12px !important;
}

.progress-bar-success {
    border-right: 1px solid white;
    line-height: 12px;
}

.progress-bar {
    line-height: 12px !important;
}

@media(max-width: 900px) {
.flight-stock-img img{
    width: 100px;
    left: -20px;
}

.flight-stock-img img.rotate{
    /* transform: rotate(45deg); */
    /* left: -55px; */
}

}

.bg-wh{
    background-color: white;
}