@import url("//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css");
@import url("//netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css");
@import "assets/css/riliwan-rabo.css";
@import "assets/css/style.css";
@import "assets/css/form.css";
@import "assets/css/loading-bars.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}
.mat-form-field-underline {
  background-color: transparent;
}
.mat-form-field-wrapper {
  padding: 0px;
}

.ngb-tp-input-container {
  width: 50% !important;
}

.mat-form-field-infix {
  line-height: unset;
  margin-top: -5px;
}

.mat-form-field-infix {
  display: table;
  padding: 0px;
  border: 0px;
}
.design_auto .mat-form-field-flex {
  border: none;
  height: 40px;
}
.control-for {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  display: table-cell;
  padding-bottom: 19px;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0px;
}

.datepkcr .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0px;
}
.mat-form-field-infix {
  border: 0px;
}

.mat-form-field-underline .ng-tns-c3-0 .ng-star-inserted {
  height: 0px !important;
}

.mat-form-field-infix {
  padding: 0px !important;
}
.mat-form-field-prefix,
.mat-form-field-suffix {
  top: -2px;
}
.mat-form-field-wrapper:focus-within {
  outline: -webkit-focus-ring-color auto 0px;
  outline-color: transparent;

  outline-width: 0px;
}
.mat-form-field:focus {
  outline: -webkit-focus-ring-color auto 0px;
  outline-color: transparent;
  outline-width: 0px;
}
::ng-deep .mat-form-field-underline {
  display: none;
}
.mat-focesed {
  outline: -webkit-focus-ring-color auto 0px;
  outline-color: transparent;

  outline-width: 0px;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
  /* height: -webkit-fill-available; */
}
.mat-form-field-flex {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
  display: none;
}
.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused)
  .mat-form-field-ripple {
  display: none;
}
.signaturepreheaderarea {
  width: 100%;
  height: auto;
  background-color: #008000;
  border: 5px #c53300;
  margin: 1% 0 2% 0;
}

.signature-pad canvas {
  border: 5px groove #008000;
  border-radius: 20px;
  background-color: ghostwhite;
  padding: 1%;
}

.btn-lg {
  border-radius: 25px !important;
}
.btn-group-lg > .btn,
.btn-lg {
  width: 100%;
  height: 75px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.flt_r {
  float: right;
}

.nav-tabs {
  border-bottom: 0px !important;
}

.pdmg {
  margin-bottom: 8%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  font: bold;
}
.pdmg .ab {
  padding: 2%;
}
.pdmg .ab:hover {
  -webkit-box-shadow: 7px 5px 17px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 5px 17px -3px rgba(0, 0, 0, 0.3);
  padding: 3%;
  -webkit-border-radius: 13px;
  border-radius: 13px;
}
.manual_entry_data_status {
  margin: 2px;
  border: 1px solid #4f73e8;
  border-radius: 4px;
  padding: 11px;
  margin-bottom: 15px;
  -webkit-box-shadow: 7px 5px 17px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 5px 17px -3px rgba(0, 0, 0, 0.3);
}
.borderdesign {
  width: 100%;
  height: 40px;
  border: none;
  padding-left: 2%;
}
.marginbottomcls {
  margin-bottom: 2%;
}

.nopointer {
  cursor: default;
}

@media (max-width: 650px) {
  .cdk-overlay-pane {
    white-space: nowrap;
    left: 2% !important;
    width: 96% !important;
  }
  .widthbutton {
    font-size: inherit !important;
  }
  .mat-option {
    overflow-x: visible;
  }
  .mobspace {
    margin-top: 3%;
  }
  .mat-datepicker-content .mat-calendar {
    width: 242px !important;
    height: 268px !important;
    margin-left: 19px !important;
    margin-right: 20px !important;
  }
  .mat-datepicker {
    margin-left: 33px !important;
  }
}

@media (max-width: 751px) {
  .mat-datepicker-popup {
    overflow: scroll;
  }
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    position: inherit !important;
  }
  .mat-autocomplete-panel {
    min-width: min-content !important;
  }
  .paddingclass {
    padding-bottom: 15px;
  }
  .maginBottom {
    margin-bottom: 2%;
  }
}

@media (max-width: 420px) {
  .flt_r {
    margin-right: -60%;
  }
  .smallscreen {
    margin-left: -29%;
  }

  .signature-pad canvas {
    border: 5px groove #008000;
    width: 100%;
    height: 180px;
  }
}
.uplaodDocument {
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
}

.visually-hidden {
  display: none;
}

.resultNew{
  /* border: 3px solid #b7e682;
  border-radius: 10px; */
  padding: 0px;
}
.resultP{
  /* font-size: 18px; */
  text-shadow: -0.075em 0 0 rgba(26, 30, 24, 0.5);
}
.tab-content{
  background-color: white !important;
}