.tab-content .tab-pane{
    padding-top: 20px;
}

.alert {
  padding: 8px;
  margin-bottom: 8px;
}

.ng-valid[required], .ng-valid.required  {
  border-left: 5px solid #42A948; /* green */
}
.ng-invalid:not(form)  {
  border-left: 5px solid #a94442; /* red */
}
