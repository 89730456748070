@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);

.board{
    width: 100%;
    margin: 60px auto;
    height: auto !important;
    background: #fff;
	padding:50px;

    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 0px 0px 3px #000000;
    /* border-radius: 10px; */

    /* New style */
    box-shadow: 0px 0px 10px 0px #cdd3d6;
    border-radius: 10px;
    /* New style */

    min-height: 450px;
    margin: 0px auto;
}

.board .nav-tabs {
    position: relative;
    margin: 40px auto;
    margin-bottom: 0;
    box-sizing: border-box;
}

.board > div.board-inner{
    background: #fafafa url(https://subtlepatterns.com/patterns/geometry2.png);
    background-size: 30%;
}

p.narrow{
    width: 60%;
    margin: 10px auto;
}

.liner{
    height: 2px;
    background: #ddd;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.firststepfirstcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 15%, #ddd 15%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.firststepsecondcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 28%, #ddd 28%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.secondstepfirstcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 35%, #ddd  35%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.firststepthiredcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 45%, #ddd  45%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.firststepforthcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 52%, #ddd  52%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.secondstepforthcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 64%, #ddd  64%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.thiredstepforthcom{
    height: 2px;
    background:linear-gradient(to right, #337ab7 75%, #ddd  75%, #ddd 100%);
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.totalnewstep{
    height: 2px;
    background: #337ab7;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
    z-index: 1;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: #fff !important;
}

.nav-tabs>li>a:hover {
    border-color: #fff #fff #fff !important;
}

span.round-tabs{
    width: 50px;
    height: 50px;
    line-height: 47px;
    display: inline-block;
    border-radius: 100px;
    background: white;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
}

span.round-tabs.one{
    color: rgb(51, 122, 183);
	border: 2px solid rgb(51, 122, 183) !important;
}

li.active span.round-tabs.one{
    background:rgb(51, 122, 183) !important;
    color: rgb(51, 122, 183);
}

span.round-tabs.two{
    color: rgb(51, 122, 183);border: 2px solid rgb(51, 122, 183);
}

li.active span.round-tabs.two{
    background: #fff !important;
    border: 2px solid rgb(51, 122, 183);
    color: rgb(51, 122, 183);
}

span.round-tabs.three{
    color: rgb(51, 122, 183);border: 2px solid rgb(51, 122, 183);
}

li.active span.round-tabs.three{
    background: #fff !important;
    border: 2px solid rgb(51, 122, 183);
    color: rgb(51, 122, 183);
}

span.round-tabs.four{
    color: rgb(51, 122, 183);border: 2px solid rgb(51, 122, 183);
}

li.active span.round-tabs.four{
    background: #fff !important;
    border: 2px solid rgb(51, 122, 183);
    color: rgb(51, 122, 183);
}

span.round-tabs.five{
    color: rgb(51, 122, 183);border: 2px solid rgb(51, 122, 183);
}

li.active span.round-tabs.five{
    background: #fff !important;
    border: 2px solid rgb(51, 122, 183);
    color: rgb(51, 122, 183);
}

.nav-tabs > li.active > a span.round-tabs{
    background: #fafafa;
}
.nav-tabs > li {
    width: 25%;
}

.nav-tabs > li:after {
    content: " ";
    position: absolute;
    left: 45%;
   opacity:0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #ddd;
    transition:0.1s ease-in-out;    
}

.nav-tabs > li.active:after {
    content: " ";
    position: absolute;
    left: 45%;
   opacity:1;
    margin: 0 auto;
    bottom: 0px;
    border: 10px solid transparent;
    border-bottom-color: #ddd;    
}

.nav-tabs > li a{
   width: 70px;
   height: 70px;
   border-radius: 100%;
   padding: 0;
}

.nav-tabs > li a:hover{
    background: transparent;
}

.tab-content .tab-pane{
   position: relative;
padding-top: 50px;
}

.tab-content .head{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.btn-outline-rounded{
    padding: 10px 40px;
    margin: 20px 0;
    border: 2px solid transparent;
    border-radius: 25px;
}

.row-div{
	padding: 15px;
	margin-top:50px;
    border: 2px solid #b7b2b2;
	margin-bottom:50px;
}

.top-div{
	    width: 20%;
    position: absolute;
    top: 155px;
    border: 2px solid #9e9c9c;
    background: #fff;
    padding: 5px;	
}

.main-div{	
	border: 1px solid !important;
    height: 50px;
	margin-top:5px;
}

.next-btn{
	float: right;
    width: 110px;
    margin-top: 40px;	
}

.main-div p{	
    padding-top: 12px;
}

.main-div button{	
    margin-top: 7px;
}
.btn.green{
    background-color:#5cb85c;
    color: #ffffff;
}

@media( max-width : 585px ){
    .board {
        width: 90%;
        height:auto !important;
    }
    span.round-tabs {
        font-size:16px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
    .tab-content .head{
        font-size:20px;
    }
    .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height:50px;
    }

    .nav-tabs > li.active:after {
        content: " ";
        position: absolute;
        left: 35%;
    }

    .btn-outline-rounded {
        padding:12px 20px;
    }
}
